import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { mq, colors } from "../utils/presets"
import SubpageTopimage from "../components/SubpageTopimage"

const Title = styled.h1``

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  h1 {
    padding: 1rem 1rem 0;
  }
  ${mq.tablet} {
    padding: 1rem 1rem 0;
    width: 35%;
    margin-right: 1rem;
    h1 {
      padding: 0;
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const ReferenzenListe = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  max-width: 1080px;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 2rem;
  li {
    margin: 0.5rem;
    width: 96%;
    position: relative;
    &:hover,
    &:focus {
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.25);
      opacity: 0.95;
    }
    ${mq.tablet} {
      width: 44%;
    }
    ${mq.xl} {
      width: 21.5%;
      &:first-child {
        width: calc(21.5% * 4);
        .iimage {
          height: 600px !important;
        }
      }
    }

    flex: 1 0 auto;
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    .iimage {
      transition: all 0.4s;
      height: 200px !important;
    }
    p {
      padding: 0 0.5rem 0.1rem 0.6rem;
      margin: 0.5rem;
      position: absolute;
      bottom: 0;
      left: -1rem;
      background: ${colors.green};
      color: white;
      font-weight: bold;
      font-size: 0.8rem;
      svg {
        align-self: center;
      }
    }
  }
`

const ReferenzPageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <SubpageTopimage
      objectFit="cover"
      objectPosition="50% 50%"
      fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
      alt={data.wordpressPage.title}
    />
    <Wrapper>
      <Textwrapper>
        <Title
          dangerouslySetInnerHTML={{
            __html: data.wordpressPage.title,
          }}
        />
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
        <ReferenzenListe>
          {data.allWordpressWpReferenzen.edges.map(referenz => (
            <li key={referenz.node.title}>
              <Link to={`/referenzen/${referenz.node.slug}`}>
                <Img
                  className="iimage"
                  fluid={
                    referenz.node.featured_media.localFile.childImageSharp.fluid
                  }
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: referenz.node.title,
                  }}
                />
              </Link>
            </li>
          ))}
        </ReferenzenListe>
      </Textwrapper>
    </Wrapper>
  </Layout>
)
export default ReferenzPageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allWordpressWpReferenzen(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
